<template>
	<ValidationForm #default="{ handleSubmit, reset }">
		<UserManagementLayout>
			<template #content>
				<UserManagementUserForm
					:user.sync="user"
					:reset-validation="reset"
					can-create-and-edit
				>
					<template #title>
						Create New CS Portal User
					</template>
				</UserManagementUserForm>
			</template>
			<template #footer>
				<div class="d-flex">
					<SecondaryActionButton
						class="mr-4"
						@click="navigateToOverview"
					>
						Back to Overview
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton @click="handleSubmit(createUser)">
						Create
					</PrimaryActionButton>
				</div>
			</template>
		</UserManagementLayout>
	</ValidationForm>
</template>

<script>
import UserManagementLayout from './user-management-layout.vue'
import UserManagementUserForm from './user-management-user-form.vue'
import SecondaryActionButton from '../secondary-action-button.vue'
import PrimaryActionButton from '../primary-action-button.vue'
import { USER_MANAGEMENT } from '../../router/route-names.js'
import { postUser } from '../../utils/api/users.js'
import { waitForLoad } from '../../state/configuration.js'
import { showSnackbar } from '../../state/snackbar.js'
import ValidationForm from '../validation-form.vue'
import { brandOptions } from '../../state/brands.js'

export default {
	components: { ValidationForm, PrimaryActionButton, SecondaryActionButton, UserManagementUserForm, UserManagementLayout },
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			user: {
				forename: '',
				surname: '',
				username: '',
				email: '',
				roleId: null,
				brandIds: [],
				isActive: true
			}
		}
	},
	computed: {
	},
	async created () {
		await waitForLoad()
		if (brandOptions.value.length === 1) this.user.brandIds = brandOptions.value.map(({ value }) => value)
	},
	methods: {
		navigateToOverview () {
			this.$router.push({ name: USER_MANAGEMENT })
		},
		async createUser () {
			await postUser(this.user)
			this.showSnackbar('This user has been created')
			this.navigateToOverview()
		}
	}
}
</script>
